import "./Pricing.css";
import { useState, useEffect, useContext } from "react";
import { Row, Col, Card, Button, Typography } from "antd";
import { currencyFormatter, findPercentage } from "../../utils";
import {
  PERIOD_DAILY,
  PERIOD_MONTHLY,
  PERIOD_YEARLY,
  PLAN_TYPE_DAILY,
} from "../../configs/constants";
import { getSubscriptionPlansAsObject } from "../../network/network";
import { AppContext } from "../../context/AppContextProvider";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

const Pricing = ({ callbackSelection }) => {
  const { displayWebView } = useContext(AppContext);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState();
  const [usersCount, setUsersCount] = useState(1);
  const [userAmount, setUserAmount] = useState(0);
  const [userDiscount, setUserDiscount] = useState(0);
  const [planAmount, setPlanAmount] = useState(0);
  const [planDiscount, setPlanDiscount] = useState(0);
  const { t } = useTranslation();

  const tabList = [
    {
      key: PERIOD_MONTHLY,
      tab: `${t("monthly")}`,
    },
    {
      key: PERIOD_YEARLY,
      tab: `${t("yearly")}`,
    },
  ];

  useEffect(() => {
    getSubscriptionPlansAsObject().then((res) => {
      if (res.data && res.data.success) {
        setSubscriptionPlans(res.data.data.subscription_plans);
        setSelectedPeriod(
          res.data.data.subscription_plans.find(
            (p) => p.period === PERIOD_DAILY
          )
            ? PERIOD_DAILY
            : PERIOD_YEARLY
        );
      }
    });
  }, []);

  useEffect(() => {
    selectedPeriod && calculateAmount();
  });

  const findPlan = (period) => {
    if (!subscriptionPlans.length) {
      return false;
    } else {
      return subscriptionPlans.find((p) => p.period === period);
    }
  };

  const calculateAmount = () => {
    let plan;
    if (selectedPeriod === PERIOD_DAILY) {
      plan = findPlan(PERIOD_DAILY);
    } else if (selectedPeriod === PERIOD_MONTHLY) {
      plan = findPlan(PERIOD_MONTHLY);
    } else {
      plan = findPlan(PERIOD_YEARLY);
    }

    setPlanAmount(plan.price);
    setPlanDiscount(plan.discount);
    setUserAmount(plan.user_price);
    setUserDiscount(plan.user_discount);
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <div
            className={
              displayWebView
                ? "pricing_head-webview pricing_head"
                : "pricing_head"
            }
          >
            <div className="user_selection">
              <h2 style={{ margin: 0 }}> {t("number_of_users")}</h2>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "20px",
                  marginTop: "20px",
                }}
                className="users_count"
              >
                <MinusOutlined
                  style={{
                    fontSize: "18px",
                    padding: "10px",
                    backgroundColor: "#6301f2",
                    color: "#ffffff",
                  }}
                  onClick={() => {
                    usersCount > 1 && setUsersCount(usersCount - 1);
                  }}
                />
                <div style={{ fontSize: "18px" }}>{usersCount}</div>
                <PlusOutlined
                  style={{
                    fontSize: "18px",
                    padding: "10px",
                    backgroundColor: "#6301f2",
                    color: "#ffffff",
                  }}
                  onClick={() => setUsersCount(usersCount + 1)}
                />
              </div>
            </div>
            {selectedPeriod && selectedPeriod !== PLAN_TYPE_DAILY && (
              <Card
                tabList={tabList}
                activeTabKey={selectedPeriod.toString()}
                onTabChange={(key) => setSelectedPeriod(parseInt(key))}
                bodyStyle={{ padding: "0" }}
                className="package_tabs"
              />
            )}
          </div>
        </Col>
      </Row>
      <Row
        gutter={{ xs: 8, sm: 16, md: 24, lg: 40 }}
        style={{ marginTop: "20px" }}
      >
        <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
          <div className="pricing_area">
            <h2>{t("pricing")}</h2>
            {findPlan(selectedPeriod) && (
              <>
                {userDiscount ? (
                  <small>
                    <Text
                      delete
                      type="danger"
                      style={{ fontWeight: "bold", fontSize: "150%" }}
                    >
                      {currencyFormatter.format(
                        selectedPeriod === PERIOD_DAILY
                          ? findPlan(selectedPeriod).user_price
                          : selectedPeriod === PERIOD_MONTHLY
                          ? findPlan(selectedPeriod).user_price
                          : findPlan(selectedPeriod).user_price / 12
                      )}
                    </Text>
                  </small>
                ) : (
                  ""
                )}
                <div style={{ fontWeight: "bold", fontSize: "250%" }}>
                  {currencyFormatter.format(
                    selectedPeriod === PERIOD_DAILY
                      ? findPlan(selectedPeriod).user_price -
                          findPercentage(
                            findPlan(selectedPeriod).user_discount,
                            findPlan(selectedPeriod).user_price
                          )
                      : selectedPeriod === PERIOD_MONTHLY
                      ? findPlan(selectedPeriod).user_price -
                        findPlan(selectedPeriod).user_discount
                      : (findPlan(selectedPeriod).user_price -
                          findPlan(selectedPeriod).user_discount) /
                        12
                  )}
                </div>
                {selectedPeriod === PERIOD_DAILY
                  ? t("day_per_user")
                  : t("month_per_user")}
              </>
            )}
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={8}>
          <div className="free_trial">
            <img
              src="images/free-trial.png"
              alt="free trial"
              style={{ width: "100%", maxWidth: "180px", marginTop: "-65px" }}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={8}>
          <div>
            {userAmount ? (
              <Row>
                <Col
                  span={12}
                  style={{
                    padding: "20px",
                    backgroundColor: "#ebebeb",
                  }}
                >
                  {usersCount} {t("users")}
                </Col>
                <Col
                  span={12}
                  style={{
                    textAlign: "right",
                    padding: "20px",
                    fontWeight: "bold",
                    border: "1px solid #ebebeb",
                  }}
                >
                  <div>
                    {userDiscount ? (
                      <small>
                        <Text delete type="danger">
                          {currencyFormatter.format(
                            selectedPeriod === PERIOD_DAILY
                              ? userAmount * usersCount
                              : selectedPeriod === PERIOD_MONTHLY
                              ? userAmount * usersCount
                              : (userAmount / 12) * usersCount
                          )}
                        </Text>
                      </small>
                    ) : (
                      ""
                    )}
                  </div>

                  {currencyFormatter.format(
                    selectedPeriod === PERIOD_DAILY
                      ? (userAmount -
                          findPercentage(userDiscount, userAmount)) *
                          usersCount
                      : selectedPeriod === PERIOD_MONTHLY
                      ? (userAmount - userDiscount) * usersCount
                      : ((userAmount - userDiscount) / 12) * usersCount
                  )}
                </Col>
              </Row>
            ) : (
              ""
            )}
            {planAmount ? (
              <Row
                style={{
                  paddingTop: "10px",
                }}
              >
                <Col
                  span={12}
                  style={{
                    padding: "20px",
                    backgroundColor: "#ebebeb",
                  }}
                >
                  {t("col_subscription_plan")}
                </Col>
                <Col
                  span={12}
                  style={{
                    textAlign: "right",
                    padding: "20px",
                    fontWeight: "bold",
                    border: "1px solid #ebebeb",
                  }}
                >
                  <div>
                    {planDiscount ? (
                      <small>
                        <Text delete type="danger">
                          {currencyFormatter.format(planAmount)}
                        </Text>
                      </small>
                    ) : (
                      ""
                    )}
                  </div>

                  {currencyFormatter.format(
                    selectedPeriod === PERIOD_DAILY
                      ? planAmount - findPercentage(planDiscount, planAmount)
                      : planAmount - planDiscount
                  )}
                </Col>
              </Row>
            ) : (
              ""
            )}
            <Row
              style={{
                paddingTop: "10px",
              }}
            >
              <Col
                span={12}
                style={{
                  padding: "20px",
                  backgroundColor: "#ebebeb",
                }}
              >
                {t("total_amount")}
              </Col>
              <Col
                span={12}
                style={{
                  textAlign: "right",
                  padding: "20px",
                  fontWeight: "bold",
                  border: "1px solid #ebebeb",
                }}
              >
                {currencyFormatter.format(
                  selectedPeriod === PERIOD_DAILY
                    ? planAmount -
                        findPercentage(planDiscount, planAmount) +
                        (userAmount -
                          findPercentage(userDiscount, userAmount)) *
                          usersCount
                    : planAmount -
                        planDiscount +
                        (userAmount - userDiscount) * usersCount
                )}
              </Col>
            </Row>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "25px",
            }}
          >
            {userAmount ? (
              <Button
                type="primary"
                size="large"
                onClick={() => callbackSelection(selectedPeriod, usersCount)}
                className={displayWebView ? "button-webview" : ""}
              >
                {t("sign_up_free_trial")}
              </Button>
            ) : (
              ""
            )}
          </div>
          <small
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "15px",
            }}
          >
            * {t("pay")}{" "}
            {currencyFormatter.format(
              selectedPeriod === PERIOD_DAILY
                ? planAmount -
                    findPercentage(planDiscount, planAmount) +
                    (userAmount - findPercentage(userDiscount, userAmount)) *
                      usersCount
                : planAmount -
                    planDiscount +
                    (userAmount - userDiscount) * usersCount
            )}{" "}
            + {t("VAT_once_trial_period_is_over")}
          </small>
        </Col>
      </Row>
    </>
  );
};

export default Pricing;
