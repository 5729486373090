import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Modal,
  Popconfirm,
  Row,
  Select,
  Table,
  message,
} from "antd";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import { useTranslation } from "react-i18next";
import {
  createSaleInvoice,
  getCustomers,
  getMyProfile,
} from "../../../network/network";
import moment from "moment";
import {
  ADDON_STOCK_MANAGEMENT,
  TAX_PERCENTAGE,
} from "../../../configs/constants";
import { AppContext } from "../../../context/AppContextProvider";
import CurrencyFormatter from "../../../components/CurrencyFormatter";
import {
  DeleteTwoTone,
  MinusCircleFilled,
  PlusCircleFilled,
} from "@ant-design/icons";

const AddInvoice = ({ reloadCallback }) => {
  const { authUser, appConfig } = useContext(AppContext);
  const [modalVisible, setModalVisible] = useState(false);
  const [loadingBTN, setLoadingBTN] = useState(false);
  const [customerData, setCustomerData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalVAT, setTotalVAT] = useState(0);
  const [productsData, setProductsData] = useState([]);
  const [cart, setCart] = useState([]);
  const { t } = useTranslation();
  const [addInvoiceForm] = Form.useForm();

  const is_stock_management_active = authUser.active_addons.some(
    (addon) => addon.id && addon.name === ADDON_STOCK_MANAGEMENT
  );

  useEffect(() => {
    setLoading(true);
    let taxTotal = 0;
    let total = 0;
    let tax = 0;
    let subtotal = 0;
    let productPrice = 0;
    cart.forEach((product) => {
      productPrice = product.price;
      if (product.is_taxable) {
        tax = productPrice * TAX_PERCENTAGE;
        taxTotal += tax * product.quantity;
      }
      subtotal = productPrice * product.quantity;
      total = total + subtotal;
    });

    setTotalVAT(taxTotal);
    setTotalAmount(total + taxTotal);
    setLoading(false);
  }, [cart]);

  useEffect(() => {
    setLoadingBTN(true);
    getCustomers({
      per_page: -1,
    })
      .then((response) => {
        let data = response.data || response.response.data;
        if (data.success === true) {
          setCustomerData(response.data.data.customers);
        }
      })
      .finally(() => {
        setLoadingBTN(false);
      });
  }, []);

  useEffect(() => {
    let categories = [{ id: 0, name: t("all"), name_ar: t("الجميع") }];
    let products = [];
    getMyProfile().then((response) => {
      let data = response.data || response.response.data;
      if (data.success === true) {
        data.data.user.categories.forEach((category) => {
          if (category.products.length > 0) {
            categories.push({
              id: category.id,
              name: category.name,
              name_ar: category.name_ar,
            });
            products = [...products, ...category.products];
          }
        });
        setProductsData(products);
      }
    });
  }, [t]);

  const showModal = () => {
    setModalVisible(true);
    setCart([]);
  };

  const handleCancel = () => {
    setCart([]);
    // Reset Form Fields
    addInvoiceForm.resetFields();
    setModalVisible(false);
  };

  const handleAdd = (status) => {
    addInvoiceForm
      .validateFields()
      .then((values) => {
        // Set Loading True
        setLoadingBTN(true);
        values.items = JSON.stringify(
          cart.map((item) => ({
            id: item.product_id,
            qty: item.quantity,
            price: item.price,
          }))
        );
        values.sale_invoice_status = status;
        // Sending Request to API
        createSaleInvoice(values)
          .then((response) => {
            let data = response.data || response.response.data;
            if (data.success === true) {
              // Success
              NotificationWithIcon(
                "success",
                t("invoice_added"),
                t("invoice_added_successfully")
              );
              reloadCallback();
            } else {
              // Error
              let errorMessages = Object.values(data.data.errors);
              errorMessages.forEach(function (key, value) {
                if (key.message) {
                  message.error(`Error in Row ${key.row}: ${key.message}`);
                } else {
                  let temp = Object.values(key);
                  message.error(temp[0]);
                }
              });
            }
            // Hide Modal
            setModalVisible(false);
            setCart([]);
          })
          .catch((info) => {
            console.log("API Failed:", info.response);
            // Error
            NotificationWithIcon(
              "error",
              t("something_went_wrong"),
              info.response.data.data.errors.name
            );
          })
          .finally(() => {
            // Set Loading False
            setLoadingBTN(false);
          });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const cartReducer = (product, action) => {
    setLoading(true);
    let updatedCart = JSON.parse(JSON.stringify(cart));
    let productId = product.product_id;
    let indexFound = updatedCart.findIndex(
      (product) => product.product_id === productId
    );

    if (action === "add") {
      if (indexFound === -1) {
        product.quantity = 1;
        updatedCart.push(product);
      } else {
        if (
          is_stock_management_active &&
          product.is_stockable &&
          product.stock <= updatedCart[indexFound].quantity
        ) {
          message.error(t("insufficient_quantity"));
        } else {
          updatedCart[indexFound].quantity++;
        }
      }
    } else if (action === "inc") {
      updatedCart[indexFound].quantity++;
    } else if (action === "dec") {
      updatedCart[indexFound].quantity--;
    } else if (action === "remove") {
      updatedCart.splice(indexFound, 1);
    }
    setCart(updatedCart);
    setLoading(false);
  };

  const handleProductSelect = (id) => {
    const product = productsData.find((product) => product.id === id);
    product.product_id = product.id;
    cartReducer(product, "add");
    addInvoiceForm.setFieldsValue({
      product: "",
    });
  };

  const tableColumns = [
    {
      title: "#",
      key: "index",
      render: (text, record, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: t("image"),
      render: (record) => {
        return (
          <>
            <img
              alt={record.name_en}
              src={record.image ? record.image : "/images/image-not-found.png"}
              style={{
                width: "50px",
                height: "auto",
                borderRadius: "1rem",
                border: "1px solid #ebebeb",
              }}
            />
          </>
        );
      },
    },
    {
      title: t("col_product"),
      render: (record) => {
        return (
          <>{appConfig.direction === "ltr" ? record.name_en : record.name}</>
        );
      },
    },
    {
      title: appConfig.direction === "ltr" ? "Unit Price" : "سعر الوحدة",
      render: (record) => {
        return (
          <>
            <CurrencyFormatter value={record.price} />
          </>
        );
      },
    },
    {
      title: t("col_quantity"),
      render: (record) => {
        return (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                columnGap: "10px",
                justifyContent: "space-between",
                backgroundColor: "#e8f0fa",
                borderRadius: "35rem",
                width: "100px",
              }}
            >
              <MinusCircleFilled
                style={{
                  color: record.quantity > 1 ? "#6301f2" : "#d9d9d9",
                  fontSize: "32px",
                  cursor: record.quantity > 1 ? "pointer" : "not-allowed",
                }}
                onClick={() =>
                  record.quantity > 1 && cartReducer(record, "dec")
                }
              />
              <span>{record.quantity}</span>
              <PlusCircleFilled
                style={{
                  color:
                    is_stock_management_active && record.is_stockable
                      ? record.stock > record.quantity
                        ? "#6301f2"
                        : "#d9d9d9"
                      : "#6301f2",
                  fontSize: "32px",
                  cursor:
                    is_stock_management_active && record.is_stockable
                      ? record.stock > record.quantity
                        ? "pointer"
                        : "not-allowed"
                      : "pointer",
                }}
                onClick={() =>
                  is_stock_management_active && record.is_stockable
                    ? record.stock > record.quantity &&
                      cartReducer(record, "inc")
                    : cartReducer(record, "inc")
                }
              />
            </div>
          </>
        );
      },
    },
    {
      title:
        appConfig.direction === "ltr"
          ? "Price including tax"
          : "السعر مع الضريبة",
      render: (record) => {
        return (
          <>
            <CurrencyFormatter
              value={
                record.is_taxable
                  ? record.quantity * record.price * TAX_PERCENTAGE +
                    record.quantity * record.price
                  : record.price
              }
            />
          </>
        );
      },
    },
    {
      title: t("action"),
      align: "center",
      width: "100px",
      render: (record) => {
        return (
          <>
            <Popconfirm
              title={t("warning_delete")}
              onConfirm={() => cartReducer(record, "remove")}
              okText={t("yes")}
              cancelText={t("no")}
            >
              <DeleteTwoTone
                twoToneColor="#ff0000"
                title={t("delete_entity")}
                style={{
                  fontSize: "22px",
                  cursor: "pointer",
                }}
              />
            </Popconfirm>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Button key="open-sales-invoice-modal" type="primary" onClick={showModal}>
        + {t("invoice_add")}
      </Button>
      <Modal
        title={t("invoice_add")}
        open={modalVisible}
        onCancel={handleCancel}
        width={1000}
        style={{
          top: 0,
        }}
        footer={[
          <Button key="back" loading={loadingBTN} onClick={handleCancel}>
            {t("cancel")}
          </Button>,
          <Button
            key="save_as_draft"
            type="primary"
            loading={loadingBTN}
            onClick={() => handleAdd(1)}
          >
            {t("save_as_draft")}
          </Button>,
          <Button
            key="issue_invoice"
            type="primary"
            loading={loadingBTN}
            onClick={() => handleAdd(2)}
          >
            {t("issue_invoice")}
          </Button>,
        ]}
      >
        <Form
          name="sales-invoice-form"
          layout="vertical"
          style={{ width: "100%" }}
          form={addInvoiceForm}
        >
          <Row gutter={[20, 20]}>
            <Col
              className="gutter-row"
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={8}
              xxl={8}
            >
              <Form.Item
                name="customer_id"
                label={t("customer_name")}
                rules={[
                  {
                    required: true,
                    message: t("validate_customer_name_required"),
                  },
                ]}
                hasFeedback
                style={{ width: "100%" }}
              >
                <Select
                  showSearch
                  allowClear
                  options={customerData.map((customer) => {
                    return {
                      label: customer.name_ar,
                      value: customer.id,
                    };
                  })}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </Form.Item>
            </Col>
            <Col
              className="gutter-row"
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={8}
              xxl={8}
            >
              <Form.Item
                name="invoice_due_date"
                label={t("col_due_date")}
                rules={[
                  {
                    required: true,
                    message: t("validate_date_required"),
                  },
                ]}
                hasFeedback
              >
                <DatePicker
                  disabledDate={(current) => {
                    return current && current < moment().add(-1, "day");
                  }}
                  placeholder=""
                  format="YYYY/MM/DD"
                  allowClear
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col
              className="gutter-row"
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              xxl={24}
            >
              <Form.Item
                name="product"
                label={t("product_add")}
                rules={[
                  cart.length < 1 && {
                    required: true,
                    message: t("validate_product_name_required"),
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  options={productsData.map((product) => {
                    return {
                      disabled:
                        is_stock_management_active && product.is_stockable
                          ? product.stock > 0
                            ? false
                            : true
                          : false,

                      label:
                        appConfig.direction === "ltr"
                          ? `${product.name_en} ${
                              is_stock_management_active && product.is_stockable
                                ? "(" + product.stock + ")"
                                : ""
                            }`
                          : `${product.name} ${
                              is_stock_management_active && product.is_stockable
                                ? "(" + product.stock + ")"
                                : ""
                            }`,
                      value: product.id,
                    };
                  })}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  size="large"
                  onChange={handleProductSelect}
                />
              </Form.Item>
            </Col>
            {cart.length > 0 && (
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                xxl={24}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    paddingBottom: "8px",
                  }}
                >
                  {appConfig.direction === "ltr" ? "Items" : "الصنف"}
                </div>
                <Table
                  columns={tableColumns}
                  dataSource={cart}
                  loading={loading}
                  rowKey="id"
                  pagination={false}
                />
                <div style={{ border: "1px solid #d9d9d9", marginTop: "30px" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "10px",
                      borderBottom: "1px solid #d9d9d9",
                    }}
                  >
                    {appConfig.direction === "ltr"
                      ? "Total without tax"
                      : "الإجمالي بدون الضريبة"}
                    <span>
                      <CurrencyFormatter value={totalAmount - totalVAT} />
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "10px",
                      borderBottom: "1px solid #d9d9d9",
                    }}
                  >
                    {appConfig.direction === "ltr"
                      ? "Total VAT"
                      : "مجموع ضریبة القیمة المضافة"}
                    <span>
                      <CurrencyFormatter value={totalVAT} />
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "10px",
                      color: "#8629ff",
                    }}
                  >
                    {appConfig.direction === "ltr"
                      ? authUser.is_vat_exempt
                        ? "Total"
                        : "Total with tax"
                      : authUser.is_vat_exempt
                      ? "الإجمالي"
                      : "الإجمالي مع الضريبة"}

                    <b>
                      <CurrencyFormatter value={totalAmount} />
                    </b>
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default AddInvoice;
