import "./style.css";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContextProvider";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Menu,
  Modal,
  Popconfirm,
  Result,
  Row,
  message,
} from "antd";
import {
  DeleteFilled,
  LeftOutlined,
  MinusCircleFilled,
  PlusCircleFilled,
  RightOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import CurrencyFormatter from "../../../components/CurrencyFormatter";
import { TAX_PERCENTAGE } from "../../../configs/constants";

import { useNavigate } from "react-router-dom";
import { placeQRproductsOrder } from "../../../network/network";
import { useTranslation } from "react-i18next";
import NotificationWithIcon from "../../../components/NotificationWithIcon";

const QRcart = () => {
  const { appConfig, setAppConfigWrapper, roundOff } = useContext(AppContext);
  const [totalVAT, setTotalVAT] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [cart, setCart] = useState([]);
  const [emptyCart, setEmptyCart] = useState(false);
  const [backLink, setBackLink] = useState([]);
  const [orderPlace, setOrderPlace] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  let navigate = useNavigate();
  const [infoForm] = Form.useForm();

  useEffect(() => {
    setTimeout(() => {
      let taxTotal = 0;
      let total = 0;
      let tax = 0;
      let subTotal = 0;
      localStorage.getItem("cart_items") && (
        <>
          {JSON.parse(localStorage.getItem("cart_items")).forEach((product) => {
            subTotal = product.price * product.quantity;
            if (product.is_taxable === true) {
              tax = subTotal * TAX_PERCENTAGE;
              taxTotal = taxTotal + tax;
              subTotal = subTotal + tax;
            }
            total = total + subTotal;
          })}
          {setCart(JSON.parse(localStorage.getItem("cart_items")))}
        </>
      );
      setTotalVAT(roundOff(taxTotal));
      setTotalAmount(roundOff(total));
    }, 500);
  }, [cart, roundOff]);

  useEffect(() => {
    setEmptyCart(cart.length > 0 ? false : true);
  }, [cart]);

  useEffect(() => {
    if (
      localStorage.getItem("src") &&
      localStorage.getItem("table") &&
      localStorage.getItem("is_drive_thru")
    ) {
      setBackLink(
        "/qr/ordering?src=" +
          localStorage.getItem("src") +
          "&table=" +
          localStorage.getItem("table") +
          "&is_drive_thru=" +
          localStorage.getItem("is_drive_thru")
      );
    }
  }, []);

  const cartReducer = (product, action) => {
    let cartNew = JSON.parse(JSON.stringify(cart));
    let productId = product.id;
    let indexFound = cartNew.findIndex((product) => product.id === productId);

    if (action === "inc") {
      cartNew[indexFound].quantity++;
    } else if (action === "dec") {
      cartNew[indexFound].quantity--;
      if (cartNew[indexFound].quantity === 0) {
        cartNew.splice(indexFound, 1);
      }
    }
    setCart(cartNew);
    localStorage.setItem("cart_items", JSON.stringify(cartNew));
  };

  const handleCheckout = () => {
    let transactionObj = {
      src: localStorage.getItem("src"),
      table: localStorage.getItem("table"),
      is_drive_thru: localStorage.getItem("is_drive_thru"),
    };

    transactionObj.items = JSON.stringify(
      cart.map((item) => ({
        id: item.id,
        qty: item.quantity,
        price: item.price,
      }))
    );

    placeQRproductsOrder(transactionObj).then((response) => {
      let data = response.data || response.response.data;
      if (data.success === true) {
        setCart([]);
        setOrderPlace(true);
        localStorage.removeItem("cart_items");
      } else {
        // Error
        message.error(t("something_went_wrong"));
      }
    });
  };

  const handleCancel = () => {
    // Reset Form Fields
    infoForm.resetFields();
    setModalVisible(false);
  };

  const handleAddEdit = () => {
    infoForm
      .validateFields()
      .then((values) => {
        // Set Loading True
        setLoading(true);

        let transactionObj = {
          customer_name: values.customer_name,
          vehicle_color: values.vehicle_color,
          vehicle_number: values.vehicle_number,
          src: localStorage.getItem("src"),
          table: localStorage.getItem("table"),
          is_drive_thru: localStorage.getItem("is_drive_thru"),
        };

        transactionObj.items = JSON.stringify(
          cart.map((item) => ({
            id: item.id,
            qty: item.quantity,
            price: item.price,
          }))
        );

        placeQRproductsOrder(transactionObj)
          .then((response) => {
            let data = response.data || response.response.data;
            if (data.success === true) {
              setModalVisible(false);
              setCart([]);
              setOrderPlace(true);
              localStorage.removeItem("cart_items");
            } else {
              // Error
              message.error(t("something_went_wrong"));
            }
          })
          .catch((info) => {
            console.log("API Failed:", info.response);
            // Error
            NotificationWithIcon(
              "error",
              t("something_went_wrong"),
              info.response.data.errors.name
            );
          })
          .finally(() => {
            // Set Loading False
            setLoading(false);
          });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <>
      <div style={{ direction: appConfig.direction }}>
        {/* header start */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            columnGap: "10px",
            boxShadow: "rgba(119, 128, 161, 0.15) 5px 10px 80px 0px",
            padding: "10px",
          }}
        >
          <Button
            onClick={() => {
              navigate(backLink);
            }}
            icon={
              appConfig.direction === "rtl" ? (
                <RightOutlined />
              ) : (
                <LeftOutlined />
              )
            }
            style={{ padding: "0", width: "40px", height: "40px" }}
          />
          <span style={{ fontSize: "16px", fontWeight: "600" }}>
            {t("basket")}
          </span>
          <div style={{ display: "flex", columnGap: "10px" }}>
            <Button
              type="primary"
              onClick={() => {
                setDrawerVisible(true);
              }}
              style={{ padding: "0", width: "40px", height: "40px" }}
            >
              {appConfig.direction === "rtl" ? "عربي" : "En"}
            </Button>
            <Popconfirm
              title={t("warning_empty_basket")}
              onConfirm={() => {
                localStorage.removeItem("cart_items");
                setCart([]);
              }}
              okText={t("yes")}
              cancelText={t("no")}
              disabled={emptyCart}
            >
              <Button
                danger
                icon={<DeleteFilled />}
                style={{ padding: "0", width: "40px", height: "40px" }}
                disabled={emptyCart}
              />
            </Popconfirm>
          </div>
        </div>
        {/* body start */}
        {!orderPlace ? (
          <>
            {!emptyCart ? (
              <div>
                {/* cart area start */}
                <span
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    padding: "10px",
                  }}
                >
                  {t("selected_item")}
                </span>
                <Row style={{ paddingBottom: "130px" }}>
                  {cart.map((product) => (
                    <Col
                      xs={24}
                      sm={12}
                      md={12}
                      lg={8}
                      xl={6}
                      xxl={4}
                      key={product.id}
                    >
                      <div
                        style={{
                          padding: "10px",
                          borderBottom: "1px solid #ebebeb",
                        }}
                      >
                        <div style={{ display: "flex", columnGap: "10px" }}>
                          <img
                            alt={product.name_en}
                            src={
                              product.image
                                ? product.image
                                : "/images/image-not-found.png"
                            }
                            style={{
                              width: "100px",
                              height: "auto",
                              cursor: "pointer",
                              borderRadius: "1rem",
                              border: "1px solid #ebebeb",
                            }}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                              width: "calc(100% - 100px)",
                            }}
                          >
                            <div
                              style={{
                                fontSize: "14px",
                                fontWeight: "600",
                              }}
                            >
                              {appConfig.direction === "rtl"
                                ? product.name
                                : product.name_en}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                                fontSize: "12px",
                                lineHeight: "1",
                                fontWeight: "600",
                              }}
                            >
                              <div>
                                <CurrencyFormatter value={product.price} />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  columnGap: "10px",
                                  justifyContent: "end",
                                  backgroundColor: "#e8f0fa",
                                  borderRadius: "35rem",
                                }}
                              >
                                {product.quantity === 1 ? (
                                  <Popconfirm
                                    title={t("warning_remove_product")}
                                    onConfirm={() => {
                                      cartReducer(product, "dec");
                                    }}
                                    okText={t("yes")}
                                    cancelText={t("no")}
                                  >
                                    <DeleteFilled
                                      title={t("delete_entity")}
                                      style={{
                                        color: "#ff0000",
                                        fontSize: "24px",
                                        padding: "4px",
                                      }}
                                    />
                                  </Popconfirm>
                                ) : (
                                  <MinusCircleFilled
                                    style={{
                                      color: "#6301f2",
                                      fontSize: "32px",
                                    }}
                                    onClick={() => cartReducer(product, "dec")}
                                  />
                                )}

                                <span>{product.quantity}</span>
                                <PlusCircleFilled
                                  style={{
                                    color: product.is_stockable
                                      ? product.stock > product.quantity
                                        ? "#6301f2"
                                        : "#d9d9d9"
                                      : "#6301f2",
                                    fontSize: "32px",
                                    cursor: product.is_stockable
                                      ? product.stock > product.quantity
                                        ? "pointer"
                                        : "not-allowed"
                                      : "pointer",
                                  }}
                                  onClick={() =>
                                    product.is_stockable
                                      ? product.stock > product.quantity &&
                                        cartReducer(product, "inc")
                                      : cartReducer(product, "inc")
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
                {/* total price area start */}
                <div
                  style={{
                    padding: "10px 0",
                    background: "#f7f7f7",
                    position: "fixed",
                    width: "100%",
                    bottom: "40px",
                  }}
                >
                  <div
                    style={{
                      padding: "0 10px 5px",
                      fontSize: "12px",
                      color: "#9d9d9d",
                      borderBottom: "1px solid #b5b5b5",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>{t("col_subtotal")}</span>
                    <span>
                      <CurrencyFormatter
                        value={roundOff(totalAmount - totalVAT)}
                      />
                    </span>
                  </div>
                  <div
                    style={{
                      padding: "7px 10px 5px",
                      fontSize: "12px",
                      color: "#9d9d9d",
                      borderBottom: "1px solid #b5b5b5",
                      display: "flex",
                      justifyContent: "space-between",
                      lineHeight: "1",
                    }}
                  >
                    <span>{t("vat")}</span>
                    <span>
                      <CurrencyFormatter value={roundOff(totalVAT)} />
                    </span>
                  </div>
                  <div
                    style={{
                      padding: "7px 10px 0",
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#515151",
                      display: "flex",
                      justifyContent: "space-between",
                      lineHeight: "1",
                    }}
                  >
                    <span>{t("total")}</span>
                    <span>
                      <CurrencyFormatter value={roundOff(totalAmount)} />
                    </span>
                  </div>
                </div>
                {/* place order */}
                <div
                  style={{
                    position: "fixed",
                    bottom: "0",
                    left: "0",
                    right: "0",
                  }}
                >
                  <Button
                    type="primary"
                    block
                    style={{
                      padding: "0 10px",
                      height: "40px",
                      lineHeight: "1",
                      borderRadius: "0",
                    }}
                    onClick={() => {
                      Number(localStorage.getItem("is_drive_thru")) === 1
                        ? setModalVisible(true)
                        : handleCheckout();
                    }}
                  >
                    {t("place_order")}
                  </Button>
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "calc(100vh - 60px)",
                }}
              >
                <div>
                  <ShoppingCartOutlined
                    style={{ color: "#a6a6a6", fontSize: "150px" }}
                  />
                </div>
                <div
                  style={{
                    marginTop: "1.5rem",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                >
                  {t("basket_empty")}
                </div>
                <Button
                  type="primary"
                  style={{
                    marginTop: "1.5rem",

                    height: "40px",

                    borderRadius: "0",
                  }}
                  onClick={() => {
                    navigate(backLink);
                  }}
                >
                  {t("menu")}
                </Button>
              </div>
            )}
          </>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "calc(100vh - 60px)",
            }}
          >
            <Result
              status="success"
              title={t("order_placed_successfully")}
              subTitle=" "
              extra={[
                <Button
                  type="primary"
                  style={{
                    height: "40px",
                    borderRadius: "0",
                  }}
                  onClick={() => {
                    navigate(backLink);
                  }}
                >
                  {t("menu")}
                </Button>,
              ]}
            />
          </div>
        )}
      </div>
      <Modal
        open={modalVisible}
        title={t("additional_info")}
        onOk={handleAddEdit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" loading={loading} onClick={handleCancel}>
            {t("cancel")}
          </Button>,
          <Button
            key="submit"
            loading={loading}
            type="primary"
            onClick={handleAddEdit}
          >
            {t("place_order")}
          </Button>,
        ]}
      >
        <Form
          name="entry-form"
          layout="vertical"
          onFinish={handleCheckout}
          style={{ width: "100%", margin: "0" }}
          form={infoForm}
        >
          <Form.Item
            name="customer_name"
            label={t("customer_name")}
            rules={[
              {
                required: true,
                message: t("validate_customer_name_required"),
                whitespace: true,
              },
            ]}
            hasFeedback
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="vehicle_number"
            label={t("vehicle_number")}
            rules={[
              {
                required: true,
                message: t("validate_vehicle_number_required"),
                whitespace: true,
              },
            ]}
            hasFeedback
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="vehicle_color"
            label={t("vehicle_color")}
            rules={[
              {
                required: true,
                message: t("validate_vehicle_color_required"),
                whitespace: true,
              },
            ]}
            hasFeedback
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      {/* Drawer */}
      <Drawer
        title={t("select_language")}
        placement={appConfig.direction === "rtl" ? "left" : "right"}
        onClose={() => {
          setDrawerVisible(false);
        }}
        open={drawerVisible}
        bodyStyle={{ padding: "0" }}
      >
        <Menu selectedKeys={[appConfig.direction]} defaultActiveFirst>
          <Menu.Item
            key="ltr"
            onClick={() => {
              setAppConfigWrapper({ direction: "ltr" });
              setDrawerVisible(false);
            }}
          >
            English
          </Menu.Item>
          <Menu.Item
            key="rtl"
            onClick={() => {
              setAppConfigWrapper({ direction: "rtl" });
              setDrawerVisible(false);
            }}
          >
            عربي
          </Menu.Item>
        </Menu>
      </Drawer>
    </>
  );
};

export default QRcart;
