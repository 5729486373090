import "./style.css";
import React, { useContext, useEffect, useState } from "react";
import {
  Anchor,
  Badge,
  Button,
  Col,
  Drawer,
  Input,
  Menu,
  Row,
  Space,
  message,
  Empty,
  Tag,
} from "antd";
import {
  BellOutlined,
  CloseOutlined,
  // DeleteFilled,
  // MinusCircleFilled,
  // PlusCircleFilled,
  SearchOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import CurrencyFormatter from "../../../components/CurrencyFormatter";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/AppContextProvider";
const { Search } = Input;

const QRmenu = ({ categoriesProducts, companyDetails }) => {
  const { appConfig, setAppConfigWrapper } = useContext(AppContext);
  const [productCategoriesList, setProductCategoriesList] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [activeCategory, setActiveCategory] = useState(0);
  const [search, setSearch] = useState("");
  const [cart, setCart] = useState([]);
  const [searchVisible, setSearchVisible] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [totalQuantity, setTotalQuantity] = useState([]);
  const [lang, setLang] = useState([]);
  const { t } = useTranslation();
  let navigate = useNavigate();

  useEffect(() => {
    let categories = [{ id: 0, name: t("all"), name_ar: t("الجميع") }];
    let products = [];
    categoriesProducts.forEach((category) => {
      if (category.products.length > 0) {
        categories.push({
          id: category.id,
          name: category.name,
          name_ar: category.name_ar,
        });
        products = [...products, ...category.products];
      }
    });
    setProductCategoriesList(categories);
    setProductsList(products);
  }, [t, categoriesProducts]);

  useEffect(() => {
    if (localStorage.getItem("cart_items")) {
      const cartItems = JSON.parse(localStorage.getItem("cart_items"));
      if (cartItems.length) {
        setCart(cartItems);
        let tempQuantity = 0;
        cartItems.map((item) => (tempQuantity = tempQuantity + item.quantity));
        setTotalQuantity(tempQuantity);
      }
    }
    if (localStorage.getItem("lang")) {
      setLang(localStorage.getItem("lang"));
    }
  }, []);

  const onChangeSearchValue = (e) => {
    setSearch(e.target.value);
  };

  const cartReducer = (product, action) => {
    let cartNew = JSON.parse(JSON.stringify(cart));
    let productId = product.id;
    let indexFound = cartNew.findIndex((product) => product.id === productId);

    if (action === "add") {
      if (indexFound === -1) {
        product.quantity = 1;
        lang === "rtl"
          ? message.success(product.name + " successfully added")
          : message.success(product.name_en + " successfully added");
        cartNew.push(product);
      } else {
        if (
          product.is_stockable &&
          product.stock <= cartNew[indexFound].quantity
        ) {
          message.error(t("insufficient_quantity"));
        } else {
          cartNew[indexFound].quantity++;
          lang === "rtl"
            ? message.success(cartNew[indexFound].name + " successfully added")
            : message.success(
                cartNew[indexFound].name_en + " successfully added"
              );
        }
      }
    } else if (action === "inc") {
      cartNew[indexFound].quantity++;
    } else if (action === "dec") {
      cartNew[indexFound].quantity--;
      if (cartNew[indexFound].quantity === 0) {
        cartNew.splice(indexFound, 1);
      }
    }
    let tempQuantity = 0;
    cartNew.map((item) => {
      return (tempQuantity = tempQuantity + item.quantity);
    });
    setTotalQuantity(tempQuantity);
    setCart(cartNew);
    localStorage.setItem("cart_items", JSON.stringify(cartNew));
  };

  return (
    <div style={{ direction: appConfig.direction }}>
      {/* header start */}
      {searchVisible ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            columnGap: "10px",
            boxShadow: "rgba(119, 128, 161, 0.15) 5px 10px 80px 0px",
            padding: "10px",
          }}
        >
          <Button
            type="primary"
            onClick={() => {
              setSearchVisible(false);
            }}
            icon={<CloseOutlined />}
            style={{ padding: "0", width: "40px", height: "40px" }}
          />

          <Search
            onChange={onChangeSearchValue}
            allowClear
            placeholder={t("search_product_name_here")}
            size="large"
            enterButton
            style={{
              width: "calc(100% - 50px)",
            }}
          />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            columnGap: "10px",
            boxShadow: "rgba(119, 128, 161, 0.15) 5px 10px 80px 0px",
            padding: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              columnGap: "10px",
            }}
          >
            <img
              alt={companyDetails.company_name}
              src={
                companyDetails.logo
                  ? companyDetails.logo
                  : "/images/image-not-found.png"
              }
              style={{
                width: "100%",
                maxWidth: "40px",
                height: "auto",
                overflow: "hidden",
                borderRadius: "35rem",
              }}
            />
            <div>
              <div
                style={{
                  fontSize: "12px",
                  color: "#9d9d9d",
                  lineHeight: "1",
                }}
              >
                {t("welcome_to")}
              </div>
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                  textTransform: "capitalize",
                  maxWidth: "150px",
                  lineHeight: "1",
                  marginTop: "3px",
                }}
              >
                {companyDetails.company_name}
              </div>
            </div>
          </div>

          <div style={{ display: "flex", columnGap: "10px" }}>
            <Button
              type="primary"
              onClick={() => {
                setSearchVisible(true);
              }}
              icon={<SearchOutlined />}
              style={{ padding: "0", width: "40px", height: "40px" }}
            />
            <Button
              type="primary"
              onClick={() => {
                setDrawerVisible(true);
              }}
              style={{ padding: "0", width: "40px", height: "40px" }}
            >
              {appConfig.direction === "rtl" ? "عربي" : "En"}
            </Button>
          </div>
        </div>
      )}

      {productCategoriesList.length < 2 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 110px)",
          }}
        >
          <Empty />
        </div>
      ) : (
        <>
          {/* category start */}
          <Anchor
            direction="horizontal"
            style={{
              padding: "10px",
              backgroundColor: "#f5f6f8",
            }}
          >
            <Space
              style={{
                overflow: "auto hidden",
                width: "max-content",
              }}
            >
              {productCategoriesList.map((category) => (
                <div
                  key={category.id}
                  onClick={() => setActiveCategory(category.id)}
                  style={{
                    background:
                      parseInt(activeCategory) === category.id
                        ? "#6301f2"
                        : "#fff",
                    color:
                      parseInt(activeCategory) === category.id
                        ? "#fff"
                        : "#000",
                    padding: "5px 15px",
                    borderRadius: "35rem",
                    cursor: "pointer",
                  }}
                >
                  {appConfig.direction === "rtl"
                    ? category.name_ar
                    : category.name}
                </div>
              ))}
            </Space>
          </Anchor>
          {/* product listing start */}
          <Row style={{ paddingBottom: "60px" }}>
            {productsList
              .filter((product) => {
                if (search === "") {
                  return product;
                } else if (
                  product.name_en.toLowerCase().includes(search.toLowerCase())
                ) {
                  return product;
                }
                return null;
              })
              .map((product) => {
                if (
                  parseInt(activeCategory) === product.category_id ||
                  parseInt(activeCategory) === 0
                ) {
                  return (
                    <Col
                      xs={24}
                      sm={12}
                      md={12}
                      lg={8}
                      xl={6}
                      xxl={4}
                      key={product.id}
                    >
                      {product.is_stockable && product.stock === 0 && (
                        <div
                          style={{
                            position: "absolute",
                            zIndex: "99",
                            margin: "10px",
                          }}
                        >
                          <Tag color="red">{t("out_of_stock")}</Tag>
                        </div>
                      )}
                      <div
                        style={{
                          padding: "10px",
                          borderBottom: "1px solid #ebebeb",
                          cursor: product.is_stockable
                            ? product.stock > 0
                              ? "pointer"
                              : "not-allowed"
                            : "pointer",
                          backgroundColor: product.is_stockable
                            ? product.stock > 0
                              ? "#ffffff"
                              : "#f0f0f0"
                            : "#ffffff",
                        }}
                      >
                        <div style={{ display: "flex", columnGap: "10px" }}>
                          <img
                            alt={product.name_en}
                            src={
                              product.image
                                ? product.image
                                : "/images/image-not-found.png"
                            }
                            style={{
                              width: "100px",
                              height: "auto",
                              cursor: "pointer",
                              borderRadius: "1rem",
                              border: "1px solid #ebebeb",
                            }}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                              width: "calc(100% - 100px)",
                            }}
                          >
                            <div
                              style={{
                                fontSize: "14px",
                                fontWeight: "600",
                              }}
                            >
                              {appConfig.direction === "rtl"
                                ? product.name
                                : product.name_en}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                                fontSize: "12px",
                                lineHeight: "1",
                                fontWeight: "600",
                              }}
                            >
                              <div>
                                <CurrencyFormatter value={product.price} />
                              </div>
                              <Button
                                type="primary"
                                style={{
                                  cursor: product.is_stockable
                                    ? product.stock > 0
                                      ? "pointer"
                                      : "not-allowed"
                                    : "pointer",
                                  backgroundColor: product.is_stockable
                                    ? product.stock > 0
                                      ? ""
                                      : "#d9d9d9"
                                    : "",
                                }}
                                onClick={() =>
                                  product.is_stockable
                                    ? product.stock > 0
                                      ? cartReducer(product, "add")
                                      : undefined
                                    : cartReducer(product, "add")
                                }
                              >
                                {t("add")}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  );
                }
                return false;
              })}
          </Row>
          {/* bottom bar */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              columnGap: "10px",
              boxShadow: "1px -2px 4px 0 rgba(0,0,0,.4)",
              padding: "10px",
              position: "fixed",
              bottom: "0",
              left: "0",
              right: "0",
              backgroundColor: "#fff",
            }}
          >
            <Button
              type="primary"
              style={{
                padding: "0 10px",
                height: "40px",
                lineHeight: "1",
                display: " flex",
                alignItems: "center",
                columnGap: "7px",
              }}
            >
              <span>{t("call_to_waiter")}</span>
              <BellOutlined style={{ fontSize: "25px", lineHeight: "0" }} />
            </Button>

            <Button
              type="primary"
              style={{
                padding: "0 10px",
                height: "40px",
                lineHeight: "1",
                display: " flex",
                alignItems: "center",
                columnGap: "7px",
              }}
              onClick={() => {
                navigate("/qr/cart");
              }}
            >
              <span>{t("order")}</span>
              <Badge count={totalQuantity}>
                <ShoppingCartOutlined
                  style={{
                    fontSize: "25px",
                    lineHeight: "0",
                    color: "#ffffff",
                  }}
                />
              </Badge>
            </Button>
          </div>
        </>
      )}

      {/* Drawer */}
      <Drawer
        title={t("select_language")}
        placement={appConfig.direction === "rtl" ? "left" : "right"}
        onClose={() => {
          setDrawerVisible(false);
        }}
        open={drawerVisible}
        bodyStyle={{ padding: "0" }}
      >
        <Menu selectedKeys={[appConfig.direction]} defaultActiveFirst>
          <Menu.Item
            key="ltr"
            onClick={() => {
              setAppConfigWrapper({ direction: "ltr" });
              setDrawerVisible(false);
            }}
          >
            English
          </Menu.Item>
          <Menu.Item
            key="rtl"
            onClick={() => {
              setAppConfigWrapper({ direction: "rtl" });
              setDrawerVisible(false);
            }}
          >
            عربي
          </Menu.Item>
        </Menu>
      </Drawer>
    </div>
  );
};

export default QRmenu;
