import { useEffect, useState, useContext } from "react";
import {
  Form,
  Input,
  Button,
  Modal,
  Row,
  Col,
  Tabs,
  Popconfirm,
  Collapse,
  Select,
  message,
  Tag,
  Empty,
  Space,
} from "antd";
import CurrencyFormatter from "../../../components/CurrencyFormatter";
import {
  PlusCircleFilled,
  MinusCircleFilled,
  EditFilled,
  FullscreenOutlined,
  FullscreenExitOutlined,
  DeleteTwoTone,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import {
  ADDON_WAITER_MANAGEMENT,
  ADDON_TABLE_MANAGEMENT,
  TAX_PERCENTAGE,
  ADDON_ORDER_MANAGEMENT,
  ADDON_JOB_MANAGEMENT,
  ADDON_STOCK_MANAGEMENT,
} from "../../../configs/constants";
import {
  getDiningTables,
  getEmployees,
  getMyProfile,
} from "../../../network/network";
import { AppContext } from "../../../context/AppContextProvider";
import "./POS.css";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import { useNavigate } from "react-router-dom";
import SaveOrder from "../../../components/SaveOrder/SaveOrder";

const POS = () => {
  const {
    authUser,
    setAuthUser,
    roundOff,
    appConfig,
    fullScreenPOS,
    setfullScreenPOS,
    isSiderCollapsed,
  } = useContext(AppContext);
  const [productCategoriesList, setProductCategoriesList] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [cart, setCart] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalVAT, setTotalVAT] = useState(0);
  const [activeCategory, setActiveCategory] = useState(0);
  const [editPriceModalVisible, setEditPriceModalVisible] = useState(false);
  const [editPriceModalTitle, setEditPriceModalTitle] = useState("");
  const [employeesData, setEmployeesData] = useState([]);
  const [tablesData, setTablesData] = useState([]);
  const [diningTableId, setDiningTableId] = useState(0);
  const [waiterId, setWaiterId] = useState(0);
  const [loginUserIsWaiter, setLoginUserIsWaiter] = useState(false);
  const [editPriceForm] = Form.useForm();
  const { t } = useTranslation();
  let navigate = useNavigate();

  const is_waiter_management_active = authUser.active_addons.some(
    (addon) => addon.id && addon.name === ADDON_WAITER_MANAGEMENT
  );

  const is_job_management_active = authUser.active_addons.some(
    (addon) => addon.id && addon.name === ADDON_JOB_MANAGEMENT
  );

  const is_table_management_active = authUser.active_addons.some(
    (addon) => addon.id && addon.name === ADDON_TABLE_MANAGEMENT
  );

  const is_order_management_active = authUser.active_addons.some(
    (addon) => addon.id && addon.name === ADDON_ORDER_MANAGEMENT
  );

  const is_stock_management_active = authUser.active_addons.some(
    (addon) => addon.id && addon.name === ADDON_STOCK_MANAGEMENT
  );

  useEffect(() => {
    getMyProfile().then((response) => {
      if (response.data.success === true) {
        setAuthUser(response.data.data.user);
        let categories = [{ id: 0, name: t("all"), name_ar: t("الجميع") }];
        let products = [];
        response.data.data.user.categories.forEach((category) => {
          if (category.products.length > 0) {
            categories.push({
              id: category.id,
              name: category.name,
              name_ar: category.name_ar,
            });
            products = [...products, ...category.products];
          }
        });
        setProductCategoriesList(categories);
        setProductsList(products);
        setLoginUserIsWaiter(response.data.data.user.is_waiter);
        if (response.data.data.user.is_waiter) {
          setWaiterId(response.data.data.user.id);
        }
      }
    });
    // eslint-disable-next-line
  }, [t]);

  useEffect(() => {
    let taxTotal = 0;
    let total = 0;
    let tax = 0;
    let subTotal = 0;
    cart.forEach((product) => {
      subTotal = product.price * product.quantity;
      if (product.is_taxable === true) {
        tax = subTotal * TAX_PERCENTAGE;
        taxTotal = taxTotal + tax;
        subTotal = subTotal + tax;
      }
      total = total + subTotal;
    });
    setTotalVAT(roundOff(taxTotal));
    setTotalAmount(roundOff(total));
  }, [cart, setTotalAmount, setTotalVAT, roundOff]);

  useEffect(() => {
    (is_job_management_active || is_waiter_management_active) &&
      getEmployees()
        .then((response) => {
          setEmployeesData(
            response.data.data.owner.concat(response.data.data.employees)
          );
        })
        .finally(() => {});
  }, [is_job_management_active, is_waiter_management_active]);

  useEffect(() => {
    is_table_management_active &&
      getDiningTables()
        .then((response) => {
          let data = response.data || response.response.data;
          if (data.success === true) {
            setTablesData(response.data.data.dining_tables);
          }
        })
        .finally(() => {});
  }, [is_table_management_active]);

  const cartReducer = (product, action) => {
    let cartNew = JSON.parse(JSON.stringify(cart));
    let productId = product.id;
    let indexFound = cartNew.findIndex((product) => product.id === productId);

    if (action === "add") {
      if (indexFound === -1) {
        product.quantity = 1;
        cartNew.push(product);
      } else {
        if (
          product.is_stockable &&
          product.stock <= cartNew[indexFound].quantity
        ) {
          message.error(t("insufficient_quantity"));
        } else {
          cartNew[indexFound].quantity++;
        }
      }
    } else if (action === "inc") {
      cartNew[indexFound].quantity++;
    } else if (action === "dec") {
      cartNew[indexFound].quantity--;
      if (cartNew[indexFound].quantity === 0) {
        cartNew.splice(indexFound, 1);
      }
    } else if (action === "edit_price") {
      cartNew[indexFound].price = roundOff(product.price);
    } else if (action === "remove") {
      cartNew.splice(indexFound, 1);
    }

    setCart(cartNew);
  };

  const decrementProduct = (product) => {
    cartReducer(product, "dec");
  };

  const showEditPriceModal = (product_id) => {
    if (cart.length > 0 && authUser.allow_editable_price === true) {
      const product = cart.find((product) => product.id === product_id);
      if (product) {
        setEditPriceModalVisible(true);
        setEditPriceModalTitle(
          t("update") + " " + t("price") + " : " + product.name
        );
        editPriceForm.setFieldsValue({
          price: product.price,
          product_id: product_id,
        });
      } else {
        // Error
        NotificationWithIcon(
          "error",
          t("something_went_wrong"),
          t("product_not_found")
        );
      }
    }
  };

  const handleEditPrice = () => {
    editPriceForm.validateFields().then((values) => {
      const productPriceToUpdate = {
        id: values.product_id,
        price: values.price,
      };
      cartReducer(productPriceToUpdate, "edit_price");
      setEditPriceModalVisible(false);
    });
  };

  const showCheckout = () => {
    navigate("/checkout", {
      state: {
        diningTableId: diningTableId > 0 && diningTableId,
        waiterId: waiterId > 0 && waiterId,
        items: cart,
      },
    });
  };

  const tabItems = productCategoriesList.map((category) => ({
    key: category.id,
    label: appConfig.direction === "rtl" ? category.name_ar : category.name,
    children: (
      <Row gutter={[20, 20]} style={{ width: "100%" }}>
        {productsList.map((product) => {
          if (
            parseInt(activeCategory) === product.category_id ||
            parseInt(activeCategory) === 0
          ) {
            return (
              <Col
                xs={24}
                sm={12}
                md={8}
                lg={6}
                xl={6}
                key={"product-" + product.id}
              >
                {is_stock_management_active &&
                  product.is_stockable &&
                  product.stock === 0 && (
                    <div
                      style={{
                        position: "absolute",
                        zIndex: "99",
                        margin: "10px",
                      }}
                    >
                      <Tag color="red">{t("out_of_stock")}</Tag>
                    </div>
                  )}
                <div
                  style={{
                    height: "100%",
                    textAlign: "center",
                    cursor: is_stock_management_active
                      ? product.is_stockable
                        ? product.stock > 0
                          ? "pointer"
                          : "not-allowed"
                        : "pointer"
                      : "pointer",
                    padding: "20px",
                    borderRadius: "0.5rem",
                    backgroundColor: is_stock_management_active
                      ? product.is_stockable
                        ? product.stock > 0
                          ? "#ffffff"
                          : "#f0f0f0"
                        : "#ffffff"
                      : "#ffffff",
                  }}
                  onClick={() =>
                    is_stock_management_active
                      ? product.is_stockable
                        ? product.stock > 0
                          ? cartReducer(product, "add")
                          : undefined
                        : cartReducer(product, "add")
                      : cartReducer(product, "add")
                  }
                >
                  <img
                    alt={product.name}
                    src={
                      product.image
                        ? product.image
                        : "/images/image-not-found.png"
                    }
                    style={{
                      width: "100%",
                      maxWidth: "195px",
                      height: "auto",
                    }}
                  />
                  <h3>
                    {appConfig.direction === "rtl"
                      ? product.name
                      : product.name_en}
                  </h3>
                  <p
                    style={{
                      fontWeight: "bold",
                      marginBottom: "0",
                    }}
                  >
                    <CurrencyFormatter value={product.price} />
                  </p>
                </div>
              </Col>
            );
          }
          return false;
        })}
      </Row>
    ),
  }));

  return (
    <>
      <div>
        {productCategoriesList.length < 2 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "calc(100vh - 110px)",
            }}
          >
            <Empty />
          </div>
        ) : (
          <>
            <div
              style={{
                overflow: "auto",
                height: fullScreenPOS
                  ? "calc(100vh - 40px)"
                  : "calc(100vh - 105px)",
                width: isSiderCollapsed
                  ? fullScreenPOS
                    ? "calc(100vw - 440px)"
                    : "calc(100vw - 590px)"
                  : fullScreenPOS
                  ? "calc(100vw - 440px)"
                  : "calc(100vw - 660px)",
              }}
            >
              <Tabs
                defaultActiveKey="0"
                onChange={(category) => setActiveCategory(category)}
                className="pos_tabs"
                items={tabItems}
              />
            </div>
            <div
              style={{
                position: "fixed",
                right: appConfig.direction === "ltr" ? 0 : "unset",
                left: appConfig.direction === "ltr" ? "unset" : 0,
                top: fullScreenPOS ? 0 : 65,
                bottom: 0,
                height: fullScreenPOS ? "100vh" : "calc(100vh - 65px)",
                boxShadow: "#0000005c 0px 0px 30px -10px",
                width: "400px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                backgroundColor: "#ffffff",
              }}
            >
              <div style={{ padding: "10px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection:
                      appConfig.direction === "ltr" ? "row" : "row-reverse",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingBottom: "10px",
                    marginBottom: "10px",
                    borderBottom: "1px solid #d9d9d9",
                  }}
                >
                  <h2 style={{ margin: 0, fontWeight: "bold" }}>{t("cart")}</h2>
                  <Button
                    onClick={() => setfullScreenPOS(!fullScreenPOS)}
                    type="primary"
                    style={{ height: "auto", padding: "10px" }}
                  >
                    {fullScreenPOS ? (
                      <FullscreenExitOutlined
                        style={{ fontSize: "20px", display: "flex" }}
                      />
                    ) : (
                      <FullscreenOutlined
                        style={{ fontSize: "20px", display: "flex" }}
                      />
                    )}
                  </Button>
                </div>

                <div
                  className="cart_card"
                  style={{
                    height: fullScreenPOS
                      ? "calc(100vh - 276px)"
                      : "calc(100vh - 341px)",
                    overflowY: "auto",
                  }}
                >
                  {cart.length < 1 ? (
                    <div style={{ textAlign: "center" }}>{t("cart_empty")}</div>
                  ) : (
                    <Collapse
                      defaultActiveKey={["1"]}
                      items={cart.map((product, i) => {
                        // Calculate subtotal for each product
                        let subTotal = product.price * product.quantity;
                        if (product.is_taxable === true) {
                          subTotal += subTotal * TAX_PERCENTAGE;
                        }

                        return {
                          key: `product-${product.id}`,
                          label: (
                            <Space>
                              <Popconfirm
                                title={t("warning_remove_product")}
                                onConfirm={() => cartReducer(product, "remove")}
                                okText={t("yes")}
                                cancelText={t("no")}
                              >
                                <DeleteTwoTone
                                  twoToneColor="#ff0000"
                                  title={t("delete_entity")}
                                />
                              </Popconfirm>
                              <strong>
                                {`${product.quantity} x ${
                                  appConfig.direction === "rtl"
                                    ? product.name
                                    : product.name_en
                                }`}
                              </strong>
                            </Space>
                          ),
                          extra: (
                            <span className="brand-color-purple">
                              <CurrencyFormatter value={product.price} />
                              {authUser.allow_editable_price === true && (
                                <EditFilled
                                  style={{
                                    color: "#F83A12",
                                    marginLeft:
                                      appConfig.direction === "ltr"
                                        ? "8px"
                                        : "0",
                                    marginRight:
                                      appConfig.direction === "ltr"
                                        ? "0"
                                        : "8px",
                                  }}
                                  onClick={(event) => {
                                    event.stopPropagation(); // Prevent Collapse from toggling when clicking the icon
                                    showEditPriceModal(product.id);
                                  }}
                                />
                              )}
                            </span>
                          ),
                          children: (
                            <div className="quantity_row">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  columnGap: "10px",
                                  justifyContent: "space-between",
                                  backgroundColor: "#e8f0fa",
                                  borderRadius: "35rem",
                                  width: "100px",
                                }}
                              >
                                <MinusCircleFilled
                                  style={{
                                    color: "#6301f2",
                                    fontSize: "24px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => decrementProduct(product)}
                                />
                                <span>{product.quantity}</span>
                                <PlusCircleFilled
                                  style={{
                                    color: product.is_stockable
                                      ? product.stock > product.quantity
                                        ? "#6301f2"
                                        : "#d9d9d9"
                                      : "#6301f2",
                                    fontSize: "26px",
                                    cursor: product.is_stockable
                                      ? product.stock > product.quantity
                                        ? "pointer"
                                        : "not-allowed"
                                      : "pointer",
                                  }}
                                  onClick={() =>
                                    product.is_stockable
                                      ? product.stock > product.quantity &&
                                        cartReducer(product, "inc")
                                      : cartReducer(product, "inc")
                                  }
                                />
                              </div>
                              <div>
                                {t("col_subtotal")}:{" "}
                                <span className="brand-color-purple">
                                  <CurrencyFormatter
                                    value={roundOff(subTotal)}
                                  />
                                </span>
                              </div>
                            </div>
                          ),
                        };
                      })}
                    />
                  )}
                </div>
              </div>
              {cart.length !== 0 && (
                <div
                  style={{ padding: "10px", borderTop: "1px solid #f0f2f5" }}
                >
                  <Row gutter={[20, 20]}>
                    {is_table_management_active && (
                      <Col
                        className="gutter-row"
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                      >
                        <b>{t("dining_table")}</b>
                        <Select
                          showSearch
                          allowClear
                          style={{
                            width: "100%",
                            height: "40px",
                            paddingTop: "5px",
                          }}
                          onChange={(id) => setDiningTableId(id)}
                          options={tablesData.map((table) => {
                            return {
                              label: table.name,
                              value: table.id,
                            };
                          })}
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          size="large"
                        />
                      </Col>
                    )}
                    {(is_waiter_management_active ||
                      is_job_management_active) && (
                      <Col
                        className="gutter-row"
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                      >
                        <b>
                          {is_waiter_management_active
                            ? t("select_waiter")
                            : t("select_job_person")}
                        </b>
                        <Select
                          showSearch
                          allowClear
                          style={{
                            width: "100%",
                            height: "40px",
                            paddingTop: "5px",
                          }}
                          onChange={(id) => setWaiterId(id)}
                          defaultValue={
                            loginUserIsWaiter ? authUser.name : null
                          }
                          disabled={loginUserIsWaiter}
                          options={employeesData
                            .filter((employee) => {
                              if (employee.is_waiter) {
                                return employee;
                              } else return null;
                            })
                            .map((employee) => {
                              return {
                                label: employee.name,
                                value: employee.id,
                              };
                            })}
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          size="large"
                        />
                      </Col>
                    )}
                  </Row>
                  <h4
                    style={{
                      display: "flex",
                      flexDirection:
                        appConfig.direction === "ltr" ? "row" : "row-reverse",
                      justifyContent: "space-between",
                      alignItems: "center",
                      columnGap: "10px",
                      paddingTop: "10px",
                      margin: 0,
                    }}
                  >
                    <span>{t("total_vat")}:</span>
                    <strong>
                      <CurrencyFormatter value={totalVAT} />
                    </strong>
                  </h4>
                  <h4
                    style={{
                      display: "flex",
                      flexDirection:
                        appConfig.direction === "ltr" ? "row" : "row-reverse",
                      justifyContent: "space-between",
                      alignItems: "center",
                      columnGap: "10px",
                    }}
                  >
                    <span>{t("total_amount")}:</span>
                    <strong>
                      <CurrencyFormatter value={totalAmount} />
                    </strong>
                  </h4>

                  <div
                    style={{
                      display: "flex",
                      flexDirection:
                        appConfig.direction === "ltr" ? "row" : "row-reverse",
                      justifyContent: "space-between",
                      alignItems: "center",
                      columnGap: "10px",
                    }}
                  >
                    {is_order_management_active && (
                      <SaveOrder
                        diningTableId={diningTableId > 0 && diningTableId}
                        waiterId={waiterId > 0 && waiterId}
                        items={cart}
                        reloadCallback={() => setCart([])}
                      />
                    )}
                    <Button
                      type="primary"
                      size="large"
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        width: "100%",
                      }}
                      onClick={showCheckout}
                    >
                      {t("checkout")}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      <Modal
        open={editPriceModalVisible}
        onCancel={() => setEditPriceModalVisible(false)}
        onOk={handleEditPrice}
        okText={t("update")}
        cancelText={t("cancel")}
        title={editPriceModalTitle}
      >
        <Form
          name="edit-price-form"
          layout="vertical"
          style={{ width: "100%", margin: "0" }}
          form={editPriceForm}
        >
          <Form.Item
            name="price"
            label={t("price")}
            rules={[
              {
                required: true,
                message: t("validate_product_price_required"),
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item name="product_id" style={{ display: "none" }}>
            <input type="hidden" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default POS;
